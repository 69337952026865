type SectionLabelProps = {
  name: string
}

function SectionLabel({ name }: SectionLabelProps) {
  return (
    <div className="section-name">
      {name}
      <style jsx>{`
        .section-name {
          @apply inline-block bg-black py-1.5 px-5 font-ibm text-base font-semibold uppercase text-white;
          margin-top: -1px;
        }
      `}</style>
    </div>
  )
}

export default SectionLabel
