function ExclusiveTag() {
  return (
    <div className="exclusive">
      Exclusive
      <style jsx>{`
        .exclusive {
          @apply mb-2 mr-2 inline-flex items-center justify-start rounded-sm bg-red-400 px-2 font-bold uppercase text-white;
          height: 19px;
          font-size: 10px;
          line-height: 9px;
        }
      `}</style>
    </div>
  )
}

export default ExclusiveTag
