import css from 'styled-jsx/css'
import cx from 'classnames'
import ExclusiveTag from './ExclusiveTag'
import NewDate from './NewDate'
import { NewArticle } from '../types'
import Link from 'next/link'

type NewResumeProps = NewArticle & {
  className?: string
}

function NewResume(props: NewResumeProps) {
  const { title, description, slug, date, type, markets, className } = props
  const isExclusive = type === 'exclusive'

  return (
    <div className={className}>
      {isExclusive && <ExclusiveTag />}
      <NewDate
        date={date}
        className={cx(
          !isExclusive && ['no-exclusive-margin', components.className]
        )}
      />
      <Link href={`/articles/${slug}`}>
        <a className="title hover:underline">{title}</a>
      </Link>
      <p
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />

      {components.styles}
      <style jsx>{`
        .title {
          @apply font-inter text-4xl font-bold;
        }
        .description {
          @apply mt-8 font-inter font-normal text-gray-800;
        }
      `}</style>
    </div>
  )
}

const components = css.resolve`
  .no-exclusive-margin {
    margin-top: 27px;
  }
`

export default NewResume
