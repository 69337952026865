import dayjs from 'dayjs'
import cx from 'classnames'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(timezone)
dayjs.extend(utc)

type DateProps = {
  date: string
  className?: string
}

function NewDate(props: DateProps) {
  const { date, className } = props
  return (
    <div className={cx('date', className)}>
      {/* @ts-ignore */}
      {dayjs(date).format('MMMM D, YYYY h:mm A')} EST.
      <style jsx>{`
        .date {
          @apply mb-4 font-ibm text-xs text-gray-500;
        }
      `}</style>
    </div>
  )
}

export default NewDate
