import cx from 'classnames'
import Link from 'next/link'
import css from 'styled-jsx/css'
import { NewArticle } from '../types'
import NewDate from './NewDate'
import SectionLabel from './SectionLabel'

type SideSectionProps = {
  name: string
  news: NewArticle[]
  className?: string
}

function SideSection(props: SideSectionProps) {
  const { name, news, className } = props
  return (
    <div className={className}>
      <div className="section-name-container">
        <SectionLabel name={name} />
      </div>
      <ul>
        {news.map((n, index) => (
          <li
            key={`side-section-${name}-item-${index}`}
            className={cx({
              'mt-8': index > 0,
            })}
          >
            <NewDate
              date={n.date}
              className={cx('date', components.className)}
            />
            <Link href={`/articles/${n.slug}`}>
              <a className="new-title hover:underline">{n.title}</a>
            </Link>
          </li>
        ))}
      </ul>
      <style jsx>{`
        .section-name-container {
          @apply mb-8 font-ibm text-xs uppercase text-black;
          border-top: 1px dashed #000000;
        }

        .new-title {
          @apply font-inter text-sm font-semibold;
        }
      `}</style>

      {components.styles}
    </div>
  )
}

const components = css.resolve`
  .date {
    @apply mb-1;
  }
`

export default SideSection
